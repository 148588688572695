import React from 'react'
import s from './QuizWelcomeScreenBlock.module.css'
import ButtonZurueck from '../ButtonZurueck'
import LogoSchauHin from '../LogoSchauHin'
const QuizBlock = (props) => {
    return (
        <div className={s.wrapper}>
            <div className={s.fullscreen} style={{ backgroundColor: props.data.strapiQuizzes.BackgroundRGB }}>
                <div className={s.limitation}> 
                <ButtonZurueck link='/Themen' />
                <LogoSchauHin />
                <div className={s.contentBlockWrapper}>
                    <div className={s.blockIcon}>
                        <h1 className={s.blockIconText}>
                            {props.data.strapiQuizzes.ShortHeadline}
                        </h1>
                        <img className={s.icon} src={props.data.strapiQuizzes.QuizImage.publicURL} alt="quiz icon" />
                    </div>

                    <div className={s.TextBlockMain}>

                        <div className={s.textBlock}>
                            <h1 className={s.headline}>
                                {props.data.strapiQuizzes.Headline}
                            </h1>
                            <h2 className={s.title}>
                                {props.data.strapiQuizzes.Title}
                            </h2>
                            <p className={s.text}>
                                {props.data.strapiQuizzes.Text}</p>
                            <div onClick={props.startQuiz} className={s.link}>
                                <p className={s.linkText} style={{ color: props.data.strapiQuizzes.BackgroundRGB }}
                                >
                                    START
                                       </p>
                            </div>
                        </div>
                    </div>


                </div>
                </div>
            </div>
        </div>
    )
}
export default QuizBlock;