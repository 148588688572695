import React from 'react'
import s from './QuizAnimation.module.css'


export default class Animation extends React.Component {


    MAX_ANIMATIONS = 9;
    currentAnimation = 0;

    constructor(props) {
        super(props);
        this.lastIndex = props.initIndex;
    }

    componentDidUpdate(prevProps) {
        this.showAnimation();
    }


    showAnimation = () => {
        
        if (this.lastIndex != this.props.currentQuestionIndex) {
            this.lastIndex = this.props.currentQuestionIndex;
            let classString = 'animation-' + this.currentAnimation ;
            let items = document.getElementsByClassName(classString);
                for (let i = 0; i < items.length; i++) {
                    items[i].beginElement();
            }

            this.currentAnimation++;
            if (this.currentAnimation === this.MAX_ANIMATIONS) {
                this.currentAnimation = 0;
            }

        }
    }


    render() {
        return (
            <div className={s.container}>
                <div className={s.animation}>
                    <h1 className={s.number}>
                        {this.props.currentQuestionIndex}
                    </h1>
                    <svg className={s.svgContainer} width="960px" height="1080px" viewBox="0 0 960 1080" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <defs>
        <pattern id="pattern-1" width="97.0761589" height="91.008899" x="406.923841" y="189.991101" patternUnits="userSpaceOnUse">
            <use xlinkHref="#image-2" transform="scale(1.51681498,1.51681498)"></use>
        </pattern>
        <image id="image-2" width="64" height="60" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAAB4CAYAAAA6//q/AAAABGdBTUEAALGN5fIAKQAAAGxlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAACQAAAAAQAAAJAAAAABAAKgAgAEAAAAAQAAAICgAwAEAAAAAQAAAHgAAAAAWuD27QAAAAlwSFlzAAAWJQAAFiUBSVIk8AAAA3hJREFUeAHt2kFu1DAUBuCk9AQgIYFYljVXmBOAxGE4BBdhh8QNegXWsEQgkOAERSGeYjvTzjxVkarK8ddNnNrOJJ+fs8k//nx9MQ3//8Zx/PH005fn+fzY8debl9+naXp2rM/89vzOlgs5TcPl8vxYOxoT9eVrRWOiPvOvBSKjqO+UXy2Acfxzfj69ywNPHfdj5rG3+s1v0u8svbaHYfxw/mh69eTj12+3FvbGP9KYNDbNSXPNb9vvxvI6JUCAAAECBAgQIECAAAECBAgQIECAAAECBDYmMF5/3x8u01e+u3wMSs//++3Fi6ur8f04Drt0nj5Dmt+m31gCIelz7h2+CO4X/+/4eV71x2nxy5/5TfrVPMC8oGlXlwU90diPubn4aaz5TfrVApjXML/ST6z9/t/RmKgvXzMaE/WZfy0QGUV9p/wOCiAPcuxH4KAA1mTKllTmt5eprAUg09dkpq9swJXrJxPYeSayFJAGAQIECBAgQIAAAQIECBAgQIAAAQIECBAgsEkBmcB5WXvONMoE5n3daaax5gFk+prM9OX6XZvJrAUwX2lNpqzcgPlN+h0UwHIxtfsQOCgAmb72Mn3LMl2zfrUAVmbKyg2Y32SmUCZQJrDsYQ0CBAgQIECAAAECBAgQIECAAAECBAgQIEBgewIygfOaygSmwu40E1f2dKfPX/MAMoEygTKBw668EU40IqOoL18uGhP13df8+gbIv+DYlcBBAazJlC21zG8vU1gLQKavyUxf2YAr108mUCaw1JAGAQIECBAgQIAAAQIECBAgQIAAAQIECBAgsD0BmcB5TWUCU2F3mokre7rT5695AJlAmcCHyKSVHbh/CQ275fmxdnSPUV++VjQm6tvq/PoGyE/o2JXAQQHI9LWX6VtW65r1qwWwMlNWbsD8JjOFMoEygWUPaxAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQLbE5AJnNdUJjAVdqeZuLKnO33+mgeQCZQJ7DETV94A+5fgsFueH2tHRlFfvlY0Juq7r/n1DZB/wbErgYMCWJMpW2qZ316msBaATF+Tmb6yAVeun0ygTGCpIQ0CBAgQIECAAAECBAgQIECAAAECBAgQIEBgewIygfOaygSmwu40E1f2dKfPX/MAMoEygQ+RSSs7cP8SGnbL82Pt6B6jvnytaEzUt9X5/wDyWjn47L/AWAAAAABJRU5ErkJggg=="></image>
        <pattern id="pattern-3" width="97.0761589" height="91.008899" x="233.923841" y="627.991101" patternUnits="userSpaceOnUse">
            <use xlinkHref="#image-4" transform="scale(1.51681498,1.51681498)"></use>
        </pattern>
        <image id="image-4" width="64" height="60" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAAB4CAYAAAA6//q/AAAABGdBTUEAALGN5fIAKQAAAGxlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAACQAAAAAQAAAJAAAAABAAKgAgAEAAAAAQAAAICgAwAEAAAAAQAAAHgAAAAAWuD27QAAAAlwSFlzAAAWJQAAFiUBSVIk8AAAA3hJREFUeAHt2kFu1DAUBuCk9AQgIYFYljVXmBOAxGE4BBdhh8QNegXWsEQgkOAERSGeYjvTzjxVkarK8ddNnNrOJJ+fs8k//nx9MQ3//8Zx/PH005fn+fzY8debl9+naXp2rM/89vzOlgs5TcPl8vxYOxoT9eVrRWOiPvOvBSKjqO+UXy2Acfxzfj69ywNPHfdj5rG3+s1v0u8svbaHYfxw/mh69eTj12+3FvbGP9KYNDbNSXPNb9vvxvI6JUCAAAECBAgQIECAAAECBAgQIECAAAECBDYmMF5/3x8u01e+u3wMSs//++3Fi6ur8f04Drt0nj5Dmt+m31gCIelz7h2+CO4X/+/4eV71x2nxy5/5TfrVPMC8oGlXlwU90diPubn4aaz5TfrVApjXML/ST6z9/t/RmKgvXzMaE/WZfy0QGUV9p/wOCiAPcuxH4KAA1mTKllTmt5eprAUg09dkpq9swJXrJxPYeSayFJAGAQIECBAgQIAAAQIECBAgQIAAAQIECBAgsEkBmcB5WXvONMoE5n3daaax5gFk+prM9OX6XZvJrAUwX2lNpqzcgPlN+h0UwHIxtfsQOCgAmb72Mn3LMl2zfrUAVmbKyg2Y32SmUCZQJrDsYQ0CBAgQIECAAAECBAgQIECAAAECBAgQIEBgewIygfOaygSmwu40E1f2dKfPX/MAMoEygTKBw668EU40IqOoL18uGhP13df8+gbIv+DYlcBBAazJlC21zG8vU1gLQKavyUxf2YAr108mUCaw1JAGAQIECBAgQIAAAQIECBAgQIAAAQIECBAgsD0BmcB5TWUCU2F3mokre7rT5695AJlAmcCHyKSVHbh/CQ275fmxdnSPUV++VjQm6tvq/PoGyE/o2JXAQQHI9LWX6VtW65r1qwWwMlNWbsD8JjOFMoEygWUPaxAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQLbE5AJnNdUJjAVdqeZuLKnO33+mgeQCZQJ7DETV94A+5fgsFueH2tHRlFfvlY0Juq7r/n1DZB/wbErgYMCWJMpW2qZ316msBaATF+Tmb6yAVeun0ygTGCpIQ0CBAgQIECAAAECBAgQIECAAAECBAgQIEBgewIygfOaygSmwu40E1f2dKfPX/MAMoEygQ+RSSs7cP8SGnbL82Pt6B6jvnytaEzUt9X5/wDyWjn47L/AWAAAAABJRU5ErkJggg=="></image>
    </defs>
    <g id="1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-2561" transform="translate(85.000000, 197.000000)">
                        {/* id="Fill-1-GF1" */}
                        <path className={s.animationPath}  id="Fill-1-GF1" d="M240.8574,643.7167 C180.0724,727.7047 108.9594,627.0007 51.3524,573.4817 C-6.2446,519.9627 -34.4966,386.5377 71.1944,342.8627 C143.8504,312.8387 182.8814,355.5387 240.4774,409.0587 C298.0844,462.5767 295.7504,567.8827 240.8574,643.7167"  fill="#FFD11B">
                           

                            <animate className="animation-0" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M562.145016,551.969636 C513.148783,620.063588 455.827582,538.417004 409.393001,495.026041 C362.96648,451.635078 340.19373,343.459677 425.386803,308.049817 C483.951751,283.707615 515.412999,318.326986 561.838714,361.71876 C608.273296,405.108913 606.391956,490.486606 562.145016,551.969636"  />
                            <animate className="animation-1" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M154.123894,396.433743 C115.227762,450.059798 69.7227737,385.760633 32.8602332,351.588931 C-3.99590836,317.41723 -22.0742755,232.225816 45.5570674,204.339475 C92.0494163,185.169252 117.025232,212.433058 153.880734,246.605398 C190.743274,280.776462 189.249755,348.013989 154.123894,396.433743" />
                            <animate className="animation-2" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M509.123894,486.433743 C470.227762,540.059798 424.722774,475.760633 387.860233,441.588931 C351.004092,407.41723 332.925725,322.225816 400.557067,294.339475 C447.049416,275.169252 472.025232,302.433058 508.880734,336.605398 C545.743274,370.776462 544.249755,438.013989 509.123894,486.433743" />     
                            <animate className="animation-3" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M307.44199,799.768099 C258.875548,866.726599 202.057165,786.441453 156.029904,743.774025 C110.010634,701.106596 87.437645,594.734996 171.88341,559.915588 C229.93463,535.979287 261.119903,570.021388 307.138375,612.689614 C353.165635,655.356245 351.300799,739.310284 307.44199,799.768099" />     
                            <animate className="animation-4" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M491.051386,73.3933021 C476.65337,93.2275966 459.808983,69.4457135 446.163733,56.806865 C432.520852,44.1680164 425.828859,12.6588633 450.863666,2.34473734 C468.073541,-4.74561913 477.318732,5.33825448 490.961377,17.9773392 C504.606626,30.6159515 504.053777,55.4846259 491.051386,73.3933021" />     
                            <animate className="animation-5" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M359.198658,780.040441 C334.668705,813.832202 305.970859,773.314919 282.723396,751.782066 C259.479969,730.249213 248.078797,676.566952 290.73069,658.994738 C320.051218,646.914871 335.802285,664.094804 359.045308,685.628059 C382.292771,707.16051 381.350879,749.529363 359.198658,780.040441" />     
                            <animate className="animation-6" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M86.011979,136.548113 C70.1096926,158.586218 51.5054434,132.161904 36.4345705,118.118739 C21.3663137,104.075574 13.9751581,69.0654037 41.6255414,57.6052637 C60.633463,49.7270899 70.84457,60.9313939 85.9125651,74.9748213 C100.983438,89.0177239 100.372828,116.649584 86.011979,136.548113" />     
                            <animate className="animation-7" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M528.011979,521.548113 C512.109693,543.586218 493.505443,517.161904 478.43457,503.118739 C463.366314,489.075574 455.975158,454.065404 483.625541,442.605264 C502.633463,434.72709 512.84457,445.931394 527.912565,459.974821 C542.983438,474.017724 542.372828,501.649584 528.011979,521.548113" />     
                            <animate className="animation-8" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M577.813842,797.233375 C559.437867,822.69963 537.939623,792.164867 520.524393,775.937209 C503.112185,759.709552 494.571294,719.253355 526.522848,706.010527 C548.487557,696.906859 560.287059,709.854055 577.698964,726.082016 C595.114195,742.30937 594.408601,774.23952 577.813842,797.233375" />     
                
                        </path>
                        {/*id="Fill-3-GF1" */}
                        <path className={s.animationPath}   id="Fill-3-GF1" d="M501.1768,109.9902 C578.8998,36.3722 608.7468,138.1842 659.0968,191.6722 C790.3528,331.0722 465.8228,494.8032 488.8978,410.2292 C511.9618,325.6592 423.6348,323.4522 341.0878,352.5672 C200.8658,402.0242 169.1168,164.0302 325.8298,160.6452 C410.2518,158.8222 423.4438,183.6112 501.1768,109.9902"  fill="#FFFFFF">
                          
                            <animate className="animation-0" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M355.1768,332.81661 C432.8998,259.19861 462.7468,361.01061 513.0968,414.49861 C644.3528,553.89861 319.8228,717.62961 342.8978,633.05561 C365.9618,548.48561 277.6348,546.27861 195.0878,575.39361 C54.8658,624.85061 23.1168,386.85661 179.8298,383.47161 C264.2518,381.64861 277.4438,406.43761 355.1768,332.81661" />
                            <animate className="animation-1" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M380.1768,64.8166105 C457.8998,-8.80138951 487.7468,93.0106105 538.0968,146.49861 C669.3528,285.89861 344.8228,449.62961 367.8978,365.05561 C390.9618,280.48561 302.6348,278.27861 220.0878,307.39361 C79.8658,356.85061 48.1168,118.85661 204.8298,115.47161 C289.2518,113.64861 302.4438,138.43761 380.1768,64.8166105" />
                            <animate className="animation-2" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M353.804027,422.575627 C418.759203,361.051109 443.703134,446.138102 485.781966,490.839445 C595.476093,607.339729 324.257749,744.174076 343.54214,673.493337 C362.817338,602.815941 289.000117,600.971492 220.013396,625.303671 C102.826145,666.6362 76.292662,467.738291 207.26188,464.909357 C277.815588,463.385827 288.840493,484.102653 353.804027,422.575627" />
                            <animate className="animation-3" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M464.866499,28.4599578 C550.547511,-52.6957453 583.450527,59.5407247 638.955834,118.505329 C783.651057,272.178412 425.892624,452.673731 451.33026,359.44025 C476.755769,266.211178 379.38502,263.778205 288.386082,295.874272 C133.806835,350.395145 98.8070739,88.0330812 271.565824,84.3014931 C364.631743,82.2918372 379.174464,109.618968 464.866499,28.4599578" />
                            <animate className="animation-4" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M227.243199,174.970189 C290.375654,115.172124 314.619627,197.871472 355.517676,241.31844 C462.133651,354.549583 198.526027,487.544191 217.269274,418.84684 C236.003586,350.152738 164.257767,348.360047 97.2068974,372.009436 C-16.6919358,412.182123 -42.4808566,218.865533 84.8132046,216.115982 C153.38709,214.635204 164.102622,234.770691 227.243199,174.970189" />
                            <animate className="animation-5" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M487.020682,322.598019 C532.588726,279.436686 550.087657,339.127817 579.607247,370.487177 C656.561035,452.215692 466.293064,548.209176 479.821654,498.624474 C493.343795,449.042117 441.558755,447.74818 393.162459,464.817948 C310.952015,493.813982 292.337964,354.28101 384.21688,352.296426 C433.712466,351.227624 441.446775,365.761111 487.020682,322.598019" />
                            <animate className="animation-6" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M122.269963,245.52807 C156.238869,213.276583 169.283526,257.879647 191.289039,281.312334 C248.654589,342.382416 106.818466,414.111725 116.903415,377.060498 C126.983557,340.011022 88.3801632,339.044152 52.3029238,351.799213 C-8.98122528,373.465949 -22.8571538,269.202584 45.6344014,267.719641 C82.5311112,266.920998 88.2966865,277.780871 122.269963,245.52807" />
                            <animate className="animation-7" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M456.269963,269.310061 C490.238869,237.058574 503.283526,281.661639 525.289039,305.094326 C582.654589,366.164408 440.818466,437.893717 450.903415,400.842489 C460.983557,363.793014 422.380163,362.826144 386.302924,375.581204 C325.018775,397.247941 311.142846,292.984575 379.634401,291.501632 C416.531111,290.70299 422.296686,301.562863 456.269963,269.310061" />
                            <animate className="animation-8" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M231.741761,278.305954 C271.675938,240.363028 287.011365,292.837222 312.88126,320.405089 C380.320761,392.252245 213.576831,476.639667 225.432795,433.049987 C237.283108,389.462369 191.900582,388.324875 149.487828,403.330829 C77.4415839,428.821107 61.128907,306.158324 141.648248,304.413685 C185.024379,303.474106 191.802446,316.250427 231.741761,278.305954" />
                          
                        </path>
                        {/* id="Fill-5-GF1" */}
                        <path className={s.animationPath} id="Fill-5-GF1" d="M375.5059,46.582 C404.5629,7.063 465.9669,14.249 501.8469,33.303 C537.7159,52.36 485.3189,93.048 459.7409,121.081 C441.3829,141.199 412.8699,152.106 395.1819,136.801 C377.4939,121.497 346.4489,86.1 375.5059,46.582"  fill="#C672E6">
                          
                            <animate className="animation-0" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M11.9101845,460.664423 C40.9671845,421.145423 102.371184,428.331423 138.251184,447.385423 C174.120184,466.442423 121.723184,507.130423 96.1451845,535.163423 C77.7871845,555.281423 49.2741845,566.188423 31.5861845,550.883423 C13.8981845,535.579423 -17.1468155,500.182423 11.9101845,460.664423" />
                            <animate className="animation-1" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M432.563763,195.141462 C448.926292,172.887589 483.504009,176.934157 503.708695,187.663813 C523.907186,198.395159 494.401475,221.307318 479.998035,237.093213 C469.660308,248.422028 453.604116,254.563959 443.643679,245.945433 C433.683242,237.327471 416.201234,217.394771 432.563763,195.141462" />
                            <animate className="animation-2" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M110.888818,602.596151 C127.695308,579.715408 163.211215,583.875964 183.964109,594.907865 C204.710641,605.941502 174.404359,629.499073 159.610113,645.729642 C148.991896,657.377578 132.500054,663.692522 122.269363,654.83122 C112.038672,645.970498 94.0823285,625.476314 110.888818,602.596151" />    
                            <animate className="animation-3" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M375.985818,241.373545 C407.667018,198.119539 474.616543,205.9847 513.736942,226.839525 C552.845347,247.697634 495.716263,292.231124 467.82826,322.91357 C447.81231,344.932955 416.72424,356.870794 397.4388,340.119293 C378.153359,323.368887 344.304619,284.626457 375.985818,241.373545" />    
                            <animate className="animation-4" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M201.220728,511.379864 C252.992443,440.543592 362.397765,453.424218 426.326222,487.577773 C490.235079,521.736704 396.877796,594.668363 351.304717,644.916426 C318.595726,680.977159 267.793271,700.527533 236.278039,673.093915 C204.762806,645.66209 149.449012,582.214343 201.220728,511.379864" />    
                            <animate className="animation-5" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M56.4836659,71.3861826 C128.41428,-27.0323539 280.419904,-9.13626337 369.240857,38.3160202 C458.034579,85.7757751 328.325787,187.105602 265.007439,256.919282 C219.562203,307.021362 148.978261,334.184271 105.191611,296.068537 C61.4049606,257.955293 -15.4469481,169.802229 56.4836659,71.3861826" />    
                            <animate className="animation-6" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M291.308819,316.882233 C350.614478,235.702613 475.940724,250.464038 549.172202,289.604615 C622.381229,328.751356 515.438371,412.332327 463.233389,469.917497 C425.764507,511.243735 367.569157,533.648809 331.467753,502.209398 C295.366349,470.772041 232.003159,398.0598 291.308819,316.882233" />    
                            <animate className="animation-7" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M76.0828186,485.15094 C122.638727,421.523669 221.021871,433.093435 278.509774,463.771185 C335.980053,494.453765 252.028167,559.963176 211.046406,605.097498 C181.632724,637.488333 135.948426,655.049067 107.608236,630.407366 C79.2680458,605.767275 29.52691,548.7766 76.0828186,485.15094" />    
                            <animate className="animation-8" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M436.560546,169.963377 C464.76454,131.410893 524.365947,138.421145 559.192643,157.009142 C594.008663,175.600065 543.149844,215.292959 518.322719,242.640356 C500.503642,262.26633 472.827677,272.906577 455.658931,257.975892 C438.490185,243.046182 408.356551,208.514886 436.560546,169.963377" />    
                          
                        </path>
                        {/* id="Fill-2168-GF1"*/}
                        <path className={s.animationPath}  id="Fill-2168-GF1" d="M688.5947,109.4189 C678.0807,145.6399 580.6577,197.2419 544.5747,186.7509 C508.5027,176.2549 515.7227,104.3009 526.2367,68.0789 C536.7607,31.8549 574.3977,-8.8121 610.4807,1.6799 C646.5637,12.1709 699.1077,73.1969 688.5947,109.4189"  fill="#FFD11B">
                          
                            <animate className="animation-0" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M366.916728,641.41843 C356.402728,677.63943 258.979728,729.24143 222.896728,718.75043 C186.824728,708.25443 194.044728,636.30043 204.558728,600.07843 C215.082728,563.85443 252.719728,523.18743 288.802728,533.67943 C324.885728,544.17043 377.429728,605.19643 366.916728,641.41843" />
                            <animate className="animation-1" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M390.916728,109.41843 C380.402728,145.63943 282.979728,197.24143 246.896728,186.75043 C210.824728,176.25443 218.044728,104.30043 228.558728,68.07843 C239.082728,31.85443 276.719728,-8.81257003 312.802728,1.67942997 C348.885728,12.17043 401.429728,73.19643 390.916728,109.41843" />
                            <animate className="animation-2" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M188.916728,315.41843 C178.402728,351.63943 80.9797275,403.24143 44.8967275,392.75043 C8.82472753,382.25443 16.0447275,310.30043 26.5587275,274.07843 C37.0827275,237.85443 74.7197275,197.18743 110.802728,207.67943 C146.885728,218.17043 199.429728,279.19643 188.916728,315.41843" />
                            <animate className="animation-3" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M617.700906,568.107956 C607.692305,602.571019 514.952346,651.668587 480.603825,641.686753 C446.265775,631.700162 453.138716,563.238351 463.147317,528.774336 C473.165437,494.308419 508.993259,455.615136 543.341779,465.597921 C577.6903,475.579755 627.708555,533.643941 617.700906,568.107956" />
                            <animate className="animation-4" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M257.017758,481.54504 C249.450279,507.602478 179.329822,544.725029 153.358989,537.177789 C127.396074,529.626952 132.592688,477.863144 140.160166,451.804986 C147.734843,425.74539 174.824171,396.489493 200.795004,404.037453 C226.765837,411.584693 264.584517,455.486882 257.017758,481.54504" />
                            <animate className="animation-5" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M507.263319,542.324087 C501.58771,561.962257 448.997367,589.939642 429.519242,584.251669 C410.047055,578.560986 413.944516,539.549228 419.620125,519.910516 C425.301132,500.270719 445.618128,478.222033 465.096253,483.910547 C484.574378,489.598519 512.938388,522.685374 507.263319,542.324087" />
                            <animate className="animation-6" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M121.263319,516.324087 C115.58771,535.962257 62.9973667,563.939642 43.5192421,558.251669 C24.0470554,552.560986 27.9445157,513.549228 33.6201248,493.910516 C39.301132,474.270719 59.6181283,452.222033 79.096253,457.910547 C98.5743776,463.598519 126.938388,496.685374 121.263319,516.324087" />
                            <animate className="animation-7" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M224.883096,698.567739 C216.27814,728.217525 136.544395,770.45789 107.013044,761.870167 C77.4906969,753.278352 83.3997496,694.378246 92.0047053,664.727641 C100.617845,635.075399 131.421033,601.786206 160.952384,610.374748 C190.483734,618.962471 233.487233,668.917134 224.883096,698.567739" />
                            <animate className="animation-8" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M488.225628,142.49413 C474.555343,189.664244 347.886131,256.864825 300.971078,243.202539 C254.070327,229.533741 263.457758,135.829028 277.128042,88.6576113 C290.811329,41.4835899 339.74689,-11.4764898 386.661943,2.18709875 C433.576996,15.849385 501.894612,95.3227129 488.225628,142.49413" />
                         
                        </path>
                        {/*  id="Fill-2170-GF1" */}
                        <path className={s.animationPath} id="Fill-2170-GF1" d="M208.1592,622.287 C226.7682,482.078 379.2632,445.333 491.2952,544.153 C560.5542,624.433 493.9332,680.354 421.0372,671.38 C320.6732,659.024 408.3592,747.395 343.9152,803.883 C292.8132,848.658 305.3432,765.344 244.0792,741.061 C198.9992,723.198 202.5382,670.416 208.1592,622.287" fill="#FFFFFF">
                        
                            <animate className="animation-0" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M73.241826,133.824591 C91.850826,-6.38440857 244.345826,-43.1294086 356.377826,55.6905914 C425.636826,135.970591 359.015826,191.891591 286.119826,182.917591 C185.755826,170.561591 273.441826,258.932591 208.997826,315.420591 C157.895826,360.195591 170.425826,276.881591 109.161826,252.598591 C64.081826,234.735591 67.620826,181.953591 73.241826,133.824591" />
                            <animate className="animation-1" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M102.951417,565.836313 C119.893385,438.18752 258.727573,404.734211 360.723519,494.701715 C423.778157,567.79007 363.125201,618.701553 296.759373,610.531462 C205.386184,599.282338 285.217094,679.736884 226.546118,731.164574 C180.021939,771.928539 191.429477,696.077976 135.65363,673.970297 C94.6119881,657.7075 97.8339573,609.653819 102.951417,565.836313" />
                            <animate className="animation-2" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M274.352831,238.980075 C287.858748,137.373343 398.535561,110.744958 479.845406,182.357887 C530.111745,240.535239 481.759996,281.060099 428.854019,274.556816 C356.012498,265.602663 419.652664,329.643406 372.880924,370.579159 C335.792452,403.02673 344.886392,342.650697 300.422611,325.05328 C267.704747,312.108311 270.273259,273.858224 274.352831,238.980075" />  
                            <animate className="animation-3" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M138.839113,510.791151 C149.396139,431.38421 235.90771,410.57379 299.464313,466.540197 C338.755469,512.006532 300.96087,543.67722 259.606417,538.594823 C202.669158,531.597039 252.414091,581.645687 215.854521,613.637494 C186.863969,638.99568 193.972333,591.811049 159.216801,578.058445 C133.642575,567.94179 135.650277,538.048864 138.839113,510.791151" />  
                            <animate className="animation-4" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M437.839113,399.791151 C448.396139,320.38421 534.90771,299.57379 598.464313,355.540197 C637.755469,401.006532 599.96087,432.67722 558.606417,427.594823 C501.669158,420.597039 551.414091,470.645687 514.854521,502.637494 C485.863969,527.99568 492.972333,480.811049 458.216801,467.058445 C432.642575,456.94179 434.650277,427.048864 437.839113,399.791151" />  
                            <animate className="animation-5" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M103.311734,599.350158 C116.581739,499.451102 225.325333,473.270252 305.214919,543.679603 C354.603243,600.879185 307.096066,640.722954 255.114211,634.32897 C183.545031,625.525308 246.073578,688.489735 200.118811,728.737493 C163.678173,760.639726 172.613268,701.278416 128.926146,683.976754 C96.7797731,671.249348 99.3034203,633.642119 103.311734,599.350158" />  
                            <animate className="animation-6" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M149.311734,95.3501578 C162.581739,-4.54889761 271.325333,-30.7297476 351.214919,39.6796031 C400.603243,96.879185 353.096066,136.722954 301.114211,130.32897 C229.545031,121.525308 292.073578,184.489735 246.118811,224.737493 C209.678173,256.639726 218.613268,197.278416 174.926146,179.976754 C142.779773,167.249348 145.30342,129.642119 149.311734,95.3501578" />  
                            <animate className="animation-7" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M437.458961,123.336544 C445.833809,60.2891402 514.463099,43.7661149 564.882304,88.2022384 C596.051825,124.30153 566.069517,149.447376 533.263191,145.412061 C488.095086,139.855972 527.557547,179.593522 498.554983,204.994329 C475.556891,225.128183 481.195929,187.6646 453.624501,176.745329 C433.336568,168.712922 434.92927,144.978582 437.458961,123.336544" />  
                            <animate className="animation-8" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M2.10624616,692.793092 C14.1966959,601.859337 113.274193,578.02805 186.062481,642.118613 C231.060733,694.184899 187.776416,730.452946 140.41517,724.632781 C75.2076951,716.61919 132.178149,773.932964 90.3082504,810.568743 C57.10678,839.607956 65.2476438,785.573943 25.4438224,769.824994 C-3.84509565,758.239791 -1.54577265,724.00757 2.10624616,692.793092" />  
                       
                        </path>
                        {/* id="Fill-2172-GF1" */}
                        <path className={s.animationPath} id="Fill-2172-GF1" d="M586.2085,710.9716 C600.3455,742.9366 567.5915,809.0026 510.2005,809.4556 C452.8185,809.9096 401.7875,750.5526 387.6505,718.5876 C373.5035,686.6226 426.5105,722.6106 474.5835,695.7346 C522.6565,668.8606 572.0615,679.0056 586.2085,710.9716"  fill="#E94E24">
                           
                            <animate className="animation-0" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M440.2085,52.6916724 C454.3455,84.6566724 421.5915,150.722672 364.2005,151.175672 C306.8185,151.629672 255.7875,92.2726724 241.6505,60.3076724 C227.5035,28.3426724 280.5105,64.3306724 328.5835,37.4546724 C376.6565,10.5806724 426.0615,20.7256724 440.2085,52.6916724" />
                            <animate className="animation-1" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M507.885532,684.691672 C522.022532,716.656672 489.268532,782.722672 431.877532,783.175672 C374.495532,783.629672 323.464532,724.272672 309.327532,692.307672 C295.180532,660.342672 348.187532,696.330672 396.260532,669.454672 C444.333532,642.580672 493.738532,652.725672 507.885532,684.691672" />
                            <animate className="animation-2" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M391.16342,107.886911 C405.390349,140.055249 372.427992,206.541512 314.671912,206.997394 C256.92489,207.454282 205.569267,147.719696 191.342338,115.551358 C177.105345,83.3830199 230.449537,119.599949 278.828343,92.5529838 C327.207148,65.508031 376.926427,75.7175661 391.16342,107.886911" />   
                            <animate className="animation-3" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M278.457279,327.5289 C298.053268,371.822227 252.651343,463.368698 173.098866,463.996412 C93.5588642,464.625512 22.8222947,382.375582 3.22630629,338.082254 C-16.3835436,293.788927 57.0920564,343.656853 123.728397,306.415262 C190.364738,269.176443 258.847429,283.234187 278.457279,327.5289" />   
                            <animate className="animation-4" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M686.885909,410.154087 C700.319201,440.51771 669.19562,503.273948 614.661413,503.704254 C560.135759,504.13551 511.644965,447.752165 498.211673,417.388542 C484.768878,387.024918 535.137311,421.209998 580.817346,395.680427 C626.497382,370.152756 673.443114,379.789513 686.885909,410.154087" />   
                            <animate className="animation-5" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M177.121914,409.582619 C185.223436,427.894702 166.453029,465.742536 133.563842,466.00205 C100.679813,466.262137 71.4353656,432.257749 63.3338439,413.945667 C55.2265915,395.633585 85.6034297,416.25036 113.15273,400.85366 C140.702029,385.458105 169.014662,391.269964 177.121914,409.582619" />   
                            <animate className="animation-6" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M564.799146,62.7098156 C587.23413,113.43681 535.254541,218.280534 444.176794,218.999424 C353.113329,219.719902 272.128705,125.523054 249.693722,74.7960596 C227.242869,24.0690649 311.363344,81.1803768 387.653713,38.5293927 C463.944082,-4.11841762 542.348294,11.9812339 564.799146,62.7098156" />   
                            <animate className="animation-7" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M257.794371,208.440209 C275.936241,249.516702 233.903364,334.414547 160.254074,334.996673 C86.6163337,335.580083 21.1287675,259.303612 2.9868984,218.227119 C-15.1678036,177.150625 52.8555434,223.396858 114.547138,188.859964 C176.238733,154.32564 239.639669,167.362431 257.794371,208.440209" />   
                            <animate className="animation-8" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M605.885909,573.223769 C619.319201,603.659966 588.19562,666.5662 533.661413,666.997534 C479.135759,667.429821 430.644965,610.911712 417.211673,580.475516 C403.768878,550.039319 454.137311,584.306106 499.817346,558.715515 C545.497382,533.126829 592.443114,542.78662 605.885909,573.223769" />   
                    
                        </path>
                        {/* id="Fill-2174-GF1" */}
                        <path className={s.animationPath}  id="Fill-2174-GF1" d="M267.7773,424.1786 C312.7233,395.9066 368.7763,421.1776 396.9193,466.3286 C568.0243,726.3596 96.3213,550.3606 267.7773,424.1786"  fill="#E3EEF2">
                          
                            <animate className="animation-0" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M418.175111,68.9087877 C463.121111,40.6367877 519.174111,65.9077877 547.317111,111.058788 C718.422111,371.089788 246.719111,195.090788 418.175111,68.9087877" />
                            <animate className="animation-1" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M378.175111,541.908788 C423.121111,513.636788 479.174111,538.907788 507.317111,584.058788 C678.422111,844.089788 206.719111,668.090788 378.175111,541.908788" />
                            <animate className="animation-2" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M49.2637314,15.3707005 C107.265074,-21.1200369 179.599637,11.4973027 215.917258,69.7738054 C436.722664,405.396433 -171.994628,178.234107 49.2637314,15.3707005" /> 
                            <animate className="animation-3" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M303.573595,618.283321 C353.698212,586.748116 416.209563,614.935941 447.595161,665.29835 C638.414648,955.342597 112.362667,759.029475 303.573595,618.283321" /> 
                            <animate className="animation-4" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M423.573595,118.283321 C473.698212,86.7481162 536.209563,114.935941 567.595161,165.29835 C758.414648,455.342597 232.362667,259.029475 423.573595,118.283321" /> 
                            <animate className="animation-5" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M33.2762563,219.373641 C72.4545218,194.746148 121.314472,216.759496 145.845997,256.09014 C294.993799,482.600885 -116.177504,329.289685 33.2762563,219.373641" /> 
                            <animate className="animation-6" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M276.404723,488.801415 C332.217336,453.662187 401.822292,485.071477 436.769437,541.18959 C649.242563,864.381751 63.4957352,645.632844 276.404723,488.801415" /> 
                            <animate className="animation-7" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M193.16765,16.5725248 C255.765325,-22.7713978 333.832061,12.3962687 373.027682,75.2293704 C611.330875,437.094097 -45.6243911,192.170107 193.16765,16.5725248" /> 
                            <animate className="animation-8" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M271.109679,570.891735 C315.978642,542.660218 371.935568,567.895033 400.030332,612.98138 C570.842061,872.640039 99.9475518,696.893054 271.109679,570.891735" /> 
                 
                        </path>
                        {/* id="Fill-2176-GF1" */}
                        <path className={s.animationPath}  id="Fill-2176-GF1" d="M239.0083,675.3827 C251.5023,667.6207 267.0843,674.5587 274.9063,686.9527 C322.4463,758.4247 191.3173,709.9027 239.0083,675.3827"  fill="#FFD11B">
                          
                            <animate className="animation-0" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M396.623467,167.269593 C409.117467,159.507593 424.699467,166.445593 432.521467,178.839593 C480.061467,250.311593 348.932467,201.789593 396.623467,167.269593" />
                            <animate className="animation-1" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M156.600428,572.490989 C173.672144,561.8294 194.963281,571.359174 205.651208,588.383102 C270.609538,686.554329 91.4357731,619.906351 156.600428,572.490989" />
                            <animate className="animation-2" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M154.044444,460.76811 C179.870886,444.700646 212.080556,459.062417 228.249471,484.718196 C326.519764,632.666384 55.4620176,532.225065 154.044444,460.76811" /> 
                            <animate className="animation-3" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M460.493438,290.376536 C481.067044,277.612662 506.725595,289.021546 519.605917,309.402305 C597.889032,426.931239 381.961675,347.141406 460.493438,290.376536" /> 
                            <animate className="animation-4" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M333.473362,581.728965 C366.960189,561.00644 408.723575,579.529099 429.688355,612.61786 C557.106617,803.429541 205.650386,673.888401 333.473362,581.728965" /> 
                            <animate className="animation-5" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M290.473362,184.728965 C323.960189,164.00644 365.723575,182.529099 386.688355,215.61786 C514.106617,406.429541 162.650386,276.888401 290.473362,184.728965" /> 
                            <animate className="animation-6" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M74.4733622,637.728965 C107.960189,617.00644 149.723575,635.529099 170.688355,668.61786 C298.106617,859.429541 -53.3496145,729.888401 74.4733622,637.728965" /> 
                            <animate className="animation-7" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M335.964483,570.63862 C380.613586,543.008587 436.2981,567.705465 464.25114,611.823814 C634.142155,866.239389 165.533847,693.517868 335.964483,570.63862" /> 
                            <animate className="animation-8" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M132.170659,206.349445 C164.125409,186.527899 203.978052,204.245225 223.983659,235.895345 C345.572327,418.410866 10.1957927,294.501949 132.170659,206.349445" /> 
                     
                        </path>
                        </g>
                        {/* id="Path-2-GF1" */}
                        <path  className={s.animationPath} id="Path-2-GF1" d="M618.679111,344.485266 C651.633377,291.264268 687.071951,271.505459 724.994831,285.208839 C781.879152,305.763911 812.106767,334.486873 812.106767,367.994177 C812.106767,401.501481 791.925717,415.31171 791.925717,443.376305 C791.925717,471.440901 810.09813,503.112895 834.671805,518.629859 C859.24548,534.146823 911.720268,605.549895 825.044551,595.845872 C738.368835,586.141848 614.218856,637 580.700999,637 C547.183142,637 474.16886,561.269366 517.219287,518.629859 C560.269714,475.990351 566.650687,472.420705 580.700999,443.376305 C590.067874,424.013372 602.727244,391.049692 618.679111,344.485266 Z"  fill="url(#pattern-1)">
                         
                            <animate className="animation-0" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M304.344906,245.659103 C325.421347,211.573744 348.086668,198.919226 372.340868,207.695549 C408.722167,220.860033 428.054734,239.255638 428.054734,260.715372 C428.054734,282.175106 415.147613,291.019859 415.147613,308.993813 C415.147613,326.967768 426.770078,347.252079 442.486574,357.18991 C458.203071,367.12774 491.764182,412.857798 436.329307,406.642862 C380.894431,400.427925 301.492276,433 280.055381,433 C258.618487,433 211.921005,384.498358 239.454612,357.18991 C266.988218,329.881461 271.069274,327.595283 280.055381,308.993813 C286.04612,296.592834 294.142628,275.481263 304.344906,245.659103 Z" />
                            <animate className="animation-1" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M569.029039,523.607173 C596.336647,479.505503 625.70288,463.132332 657.127739,474.487662 C704.265027,491.520656 729.313192,515.321988 729.313192,543.087871 C729.313192,570.853755 712.59013,582.297625 712.59013,605.553399 C712.59013,628.809173 727.648731,655.054225 748.011749,667.912383 C768.374766,680.770541 811.858087,739.938818 740.034109,731.897562 C668.21013,723.856307 565.33304,766 537.558412,766 C509.783783,766 449.280376,703.245682 484.954185,667.912383 C520.627994,632.579083 525.915597,629.62109 537.558412,605.553399 C545.320289,589.508272 555.810497,562.192863 569.029039,523.607173 Z" />
                            <animate className="animation-2" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M274.6397,577.49882 C302.410149,532.649664 332.274116,515.998982 364.231599,527.546775 C412.167824,544.868464 437.640534,569.073208 437.640534,597.3097 C437.640534,625.546191 420.634031,637.184025 420.634031,660.833965 C420.634031,684.483905 435.947863,711.173788 456.656015,724.249881 C477.364168,737.325974 521.584496,797.497103 448.543161,789.319555 C375.501827,781.142007 270.881058,824 242.635673,824 C214.390288,824 152.861399,760.18205 189.139849,724.249881 C225.418299,688.317712 230.795522,685.309583 242.635673,660.833965 C250.529107,644.516886 261.197116,616.738505 274.6397,577.49882 Z" />
                            <animate className="animation-3" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M520.45072,594.103784 C549.028945,547.909154 579.761583,530.758951 612.648634,542.653178 C661.97921,560.494518 688.19286,585.425404 688.19286,614.508991 C688.19286,643.592577 670.691678,655.579546 670.691678,679.938984 C670.691678,704.298422 686.450953,731.789001 707.761457,745.257377 C729.071961,758.725753 774.578552,820.702016 699.412619,812.279141 C624.246686,803.856267 516.582747,848 487.515771,848 C458.448795,848 395.130177,782.267511 432.463881,745.257377 C469.797584,708.247243 475.331219,705.148871 487.515771,679.938984 C495.638806,663.132393 506.617123,634.52066 520.45072,594.103784 Z" />
                            <animate className="animation-4" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M275.623703,185.943501 C310.860932,128.985073 348.754573,107.838707 389.304626,122.504404 C450.129706,144.502949 482.451391,175.242974 482.451391,211.103319 C482.451391,246.963663 460.872264,261.743712 460.872264,291.779136 C460.872264,321.814559 480.303602,355.71071 506.57966,372.317349 C532.855719,388.923987 588.965788,465.341321 496.285462,454.955834 C403.605137,444.570348 270.854456,499 235.014592,499 C199.174728,499 121.102257,417.951203 167.135076,372.317349 C213.167895,326.683494 219.99092,322.863171 235.014592,291.779136 C245.030373,271.056446 258.566743,235.777901 275.623703,185.943501 Z" />
                            <animate className="animation-5" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M584.423256,223.778725 C614.430392,175.341637 646.699662,157.358901 681.231066,169.830517 C733.028171,188.537941 760.552503,214.679064 760.552503,245.174476 C760.552503,275.669887 742.176262,288.238747 742.176262,313.780682 C742.176262,339.322617 758.723501,368.147691 781.09953,382.269871 C803.475559,396.392052 851.25748,461.376871 772.33325,452.545119 C693.40902,443.713367 580.361885,490 549.84156,490 C519.321235,490 452.836686,421.076614 492.037075,382.269871 C531.237464,343.463129 537.04778,340.21435 549.84156,313.780682 C558.370747,296.158237 569.897979,266.157585 584.423256,223.778725 Z" />
                            <animate className="animation-6" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M210.423256,564.778725 C240.430392,516.341637 272.699662,498.358901 307.231066,510.830517 C359.028171,529.537941 386.552503,555.679064 386.552503,586.174476 C386.552503,616.669887 368.176262,629.238747 368.176262,654.780682 C368.176262,680.322617 384.723501,709.147691 407.09953,723.269871 C429.475559,737.392052 477.25748,802.376871 398.33325,793.545119 C319.40902,784.713367 206.361885,831 175.84156,831 C145.321235,831 78.8366856,762.076614 118.037075,723.269871 C157.237464,684.463129 163.04778,681.21435 175.84156,654.780682 C184.370747,637.158237 195.897979,607.157585 210.423256,564.778725 Z" />
                            <animate className="animation-7" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M578.423256,564.778725 C608.430392,516.341637 640.699662,498.358901 675.231066,510.830517 C727.028171,529.537941 754.552503,555.679064 754.552503,586.174476 C754.552503,616.669887 736.176262,629.238747 736.176262,654.780682 C736.176262,680.322617 752.723501,709.147691 775.09953,723.269871 C797.475559,737.392052 845.25748,802.376871 766.33325,793.545119 C687.40902,784.713367 574.361885,831 543.84156,831 C513.321235,831 446.836686,762.076614 486.037075,723.269871 C525.237464,684.463129 531.04778,681.21435 543.84156,654.780682 C552.370747,637.158237 563.897979,607.157585 578.423256,564.778725 Z" />
                            <animate className="animation-8" begin="indefinite" fill="freeze" attributeName="d" dur="300ms" to="M242.23505,681.537196 C269.027136,638.332509 297.838984,622.292353 328.670594,633.416726 C374.91801,650.103287 399.493306,673.420524 399.493306,700.621677 C399.493306,727.822831 383.085949,739.033944 383.085949,761.81672 C383.085949,784.599495 397.860268,810.310749 417.838866,822.907385 C437.817463,835.504022 480.479893,893.468876 410.01183,885.591171 C339.543768,877.713466 238.608826,919 211.358536,919 C184.108246,919 124.747041,857.522041 159.747388,822.907385 C194.747735,788.292729 199.935518,785.394898 211.358536,761.81672 C218.973881,746.097934 229.266052,719.338093 242.23505,681.537196 Z" />
                 
                        </path>
                        {/* id="Oval-GF1" */}
                        <path className={s.animationPath} id="Oval-GF1" d="M438.628702,1014 C502.15517,1014 530,864.030593 530,821.262503 C530,778.494414 489.431731,719 425.905263,719 C367.095764,719 337.015626,734.285099 331.207063,775.853603 C330.741172,779.187712 331.207063,844.194876 331.207063,847.370493 C331.207063,890.138582 375.102235,1014 438.628702,1014 Z"  fill="url(#pattern-3)">
                           
                            <animate className="animation-0" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M642.628702,866 C706.15517,866 734,716.030593 734,673.262503 C734,630.494414 693.431731,571 629.905263,571 C571.095764,571 541.015626,586.285099 535.207063,627.853603 C534.741172,631.187712 535.207063,696.194876 535.207063,699.370493 C535.207063,742.138582 579.102235,866 642.628702,866 Z" />
                            <animate className="animation-1" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"   to="M329.313287,506.98995 C402.09738,506.98995 434,335.165704 434,286.165079 C434,237.164454 387.519772,169 314.735679,169 C247.355951,169 212.892275,186.512575 206.237238,234.138801 C205.703453,237.958786 206.237238,312.439355 206.237238,316.07775 C206.237238,365.078375 256.529194,506.98995 329.313287,506.98995 Z" />
                            <animate className="animation-2" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M695.597681,971 C754.974179,971 781,831.19801 781,791.329452 C781,751.460894 743.081919,696 683.705422,696 C628.737749,696 600.622646,710.248821 595.193536,748.999121 C594.75808,752.10719 595.193536,812.707088 595.193536,815.667408 C595.193536,855.535966 636.221184,971 695.597681,971 Z" />
                            <animate className="animation-3" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M271.03718,664 C337.755932,664 367,506.9134 367,462.115639 C367,417.317878 324.393124,355 257.674372,355 C195.909622,355 164.317919,371.010493 158.217468,414.55174 C157.728165,418.044078 158.217468,486.136328 158.217468,489.462652 C158.217468,534.260413 204.318428,664 271.03718,664 Z" />
                            <animate className="animation-4" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M635.795868,885 C687.616258,885 710.330097,762.990991 710.330097,728.196613 C710.330097,693.402235 677.237378,645 625.416988,645 C577.444366,645 552.907121,657.435334 548.168907,691.253779 C547.788866,693.966275 548.168907,746.853458 548.168907,749.437011 C548.168907,784.231389 583.975478,885 635.795868,885 Z" />
                            <animate className="animation-5" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M253.327985,995 C330.842652,995 364.81877,812.494857 364.81877,760.448267 C364.81877,708.401677 315.317578,636 237.802911,636 C166.043865,636 129.340236,654.601188 122.252657,705.187944 C121.684179,709.245386 122.252657,788.355798 122.252657,792.220362 C122.252657,844.266952 175.813319,995 253.327985,995 Z" />
                            <animate className="animation-6" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M556.208099,433 C621.84726,433 650.618123,278.455255 650.618123,234.382376 C650.618123,190.309498 608.700679,129 543.061518,129 C482.296197,129 451.215687,144.751424 445.213949,187.58812 C444.732564,191.023948 445.213949,258.014381 445.213949,261.28688 C445.213949,305.359759 490.568938,433 556.208099,433 Z" />
                            <animate className="animation-7" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M274.208099,774 C339.84726,774 368.618123,619.455255 368.618123,575.382376 C368.618123,531.309498 326.700679,470 261.061518,470 C200.296197,470 169.215687,485.751424 163.213949,528.58812 C162.732564,532.023948 163.213949,599.014381 163.213949,602.28688 C163.213949,646.359759 208.568938,774 274.208099,774 Z" />
                            <animate className="animation-8" begin="indefinite" fill="freeze" attributeName="d" dur="300ms"  to="M700.326446,766 C786.693763,766 824.550162,562.651651 824.550162,504.661021 C824.550162,446.670392 769.39563,366 683.028313,366 C603.073944,366 562.178536,386.725557 554.281512,443.089631 C553.64811,447.610458 554.281512,535.755764 554.281512,540.061685 C554.281512,598.052314 613.959129,766 700.326446,766 Z" />
                
                        </path>
                        </g>
                    </svg>

                </div>


            </div>

        )
    }
}