import React from 'react'
import s from '../FinishScreen/FinishScreen.module.css'
import { Link } from 'gatsby'
import QuizAnimation from '../QuizQuestion/QuizAnimation'
import circleCheck from '../../images/circle-check.svg'
import circleX from '../../images/circle-x.svg'
import ButtonZurueck from '../ButtonZurueck'
import LogoSchauHin from '../LogoSchauHin'
import ModalShare from './ModalShare'
import TestModal from '../TestModal'
export default class FinishScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visibleAnswers: [],
            currentCorrectAnswersCount: 0,
            currentTextAnimation:'',
            currentButtonAnimationClass:''

        };

        this.modalShare = React.createRef();
    }

    componentDidMount(newProps) {
        this.runAnimation();
    }


    numberToWord = (number) => {
        let words = ["keine", "eine", "zwei", "drei", "vier", "fünf", "sechs", "sieben", "acht", "neun", "zehn", "elf", "zwölf"];
        if (number > words.length) return number;
        return words[number];
    }

    getLevel = (correctAnswersCount, answersCount) => {

        let percentage = correctAnswersCount / answersCount * 100;

        this.props.data.score.sort((a, b) => {
            return b["Prozent"] - a["Prozent"];
        });

        for(let i = 0; i < this.props.data.score.length; i++) {
            if(percentage>=this.props.data.score[i]["Prozent"]) {
                return this.props.data.score[i]["Name"];
            }
        }
    }

    runAnimation() {

        var currentAnswer = 0;
        const interval = setInterval(() => {
            if (currentAnswer < this.props.correctAnswers.length) {

                let answer = this.props.correctAnswers[currentAnswer];
                this.state.visibleAnswers.push(answer);
                if (answer) {
                    this.state.currentCorrectAnswersCount++;
                }
                this.setState(this.state);
                currentAnswer++;

            } else {
                clearInterval(interval);
                this.state.currentTextAnimation = s.textAnimation;
                this.state.currentTextAnimation = s.textAnimationGelb;
                this.state.currentButtonAnimationClass = s.buttonBlockAnimation;
                this.setState(this.state);
            }
        }, 400);
    }

    showModal() {
        this.modalShare.current.showModal();
    }

    render() {

        let correctAnswersCount = 0;
        for (let i = 0; i < this.props.correctAnswers.length; i++) {
            if (this.props.correctAnswers[i]) {
                correctAnswersCount++;
            }
        }

        let answersCount = this.props.correctAnswers.length;

        let correctAnswersCountString = this.numberToWord(correctAnswersCount);
        let answersCountString = this.numberToWord(answersCount);
        let level = this.getLevel(correctAnswersCount, answersCount);


        return (
            <div className={s.wrapper}>
                <div style={{ backgroundColor: this.props.background }} className={s.fullscreen}>
                       <div className={s.limitation}>
                       <ButtonZurueck onClick={this.props.resetState}/>
                            <LogoSchauHin />
                        <div className={s.content}>
                        <div className={s.container}>
                            <div className={s.animationBlock}>
                                <QuizAnimation initIndex={0} showMiddle={false} currentQuestionIndex={this.state.currentCorrectAnswersCount}/>
                            </div>
                            <div className={s.answerBlock}>
                                <div className={s.answerCircle}>
                                    {this.state.visibleAnswers.map(x => x ? <img className={s.circle} src={circleCheck} alt="circleCheck" /> : <img className={s.circle} src={circleX} alt="circleX" />)}

                                </div>
                                <h2 className={`${s.text} ${this.state.currentTextAnimation }`}>
                                    Sie haben {correctAnswersCountString} von {answersCountString} Fragen richtig beantwortet.
                                    </h2>
                                  <h2 className={`${s.textGelb} ${this.state.currentTextAnimation }`}>
                                  Damit sind Sie {level}!
                                  </h2>
                       
                                <div className={`${s.buttonBlock} ${this.state.currentButtonAnimationClass}`}>
                                    <Link to='/Themen' className={s.link}>
                                        <p className={s.linkText}
                                            style={{ color: this.props.background }}
                                        >
                                            {this.props.data.BackToQuizesButtonText}
                                        </p>
                                    </Link>
                                    <div className={s.link} onClick={() => this.showModal()}>
                                        <p className={s.linkText}
                                            style={{ color: this.props.background }}>
                                            {this.props.data.ShareButtonText}
                                        </p>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        </div>
                       </div>
                    </div>
                    <TestModal ref={this.modalShare} />
                </div>
         
        )
    }



}
