import React from 'react'
import { graphql } from "gatsby";
import QuizMainComponent from '../components/QuizMainComponent/QuizMainComponent'
import  Helmet from 'react-helmet'

const Quiz = ({data}) => {

    return (
      <>
                <Helmet >
                <script id="_etLoader" type="text/javascript" charset="UTF-8" data-block-cookies="true" data-respect-dnt="true"
                 data-secure-code="8bbt9s" src="//static.etracker.com/code/e.js"/>
</Helmet>
        <QuizMainComponent    data={data} />
      </>
    )
  }
export default Quiz;

export const query = graphql`
query QuizTamplete($id: String!) {
  logoSchauHin: file(relativePath: { eq: "LogoSchauHin.svg" }){
    publicURL
     } ,
     buttonZurueck: file(relativePath: { eq: "ButtonZurueck.svg" }){
    publicURL
     } ,
            strapiQuizzes(id:{eq:$id}) {
                id,
                Headline,
                ShortHeadline,
                Title,
                Text,
                QuizImage {
                    publicURL
                  },
                BackgroundRGB,
                Question {
                    id,
                    Answer {
                      AnswerText,
                      Correct,
                      id
                    },
                    QuestionText
                  }  
         
              },
   strapiFinishScreen {
    BackToQuizesButtonText
    ShareButtonText
    score {
      Name
      Prozent
      id
    }
  }
  
  }`

