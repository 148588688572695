import React, { Component } from 'react';
import s from './ModalShare.module.css'
import { StaticQuery, graphql } from 'gatsby'

import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';

export default class ModalShare extends Component {


    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    open() {
        this.modal.current.open();
    }

    shareFacebook(url, quote) {
        url = encodeURI(url);
        quote = encodeURI(quote);
        let shareLink = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${quote}`;
        return shareLink;
    }

    shareEmail(emailBetreff, emailText) {
        emailText = encodeURI(emailText);
        emailBetreff = encodeURI(emailBetreff);

        return `mailto:%20?subject=${emailBetreff}&body=${emailText}`;

    }

    shareTwitter(url, text) {
        url = encodeURI(url);
        text = encodeURI(text);
        let shareLink = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
        return shareLink;
    }

    render() {

        return (

            <StaticQuery
                query={graphql`
        query MyQueryShareBlock {
            facebookIcon: file(relativePath: { eq: "facebook.png" }){
                publicURL
            },
            twitterIcon: file(relativePath: { eq: "twitter.png" }){
                publicURL
            },
                emailIcon: file(relativePath: { eq: "mail.png" }){
                publicURL
            },
            strapiQuizTeilen{
                EmailBetreff,
                EmailText,
                URL,
                Beschreibung
            }    
        }
        `}

                render={d => {

                    return (
                        <>
                            
                            <PureModal
                                header="MEDIENQUIZ TEILEN"
                                onClose={() => {
                                    console.log('handle closing');
                                    return true;
                                }}
                                ref={this.modal}
                                >
                                <div className={s.ShareIconBlock}>
                                        <a href={this.shareFacebook(d.strapiQuizTeilen.URL, d.strapiQuizTeilen.Beschreibung)}
                                            target="_blank">
                                            <img className={s.iconShare} src={d.facebookIcon.publicURL} alt="facebook share" />
                                        </a>
                                        <a href={this.shareEmail(d.strapiQuizTeilen.EmailBetreff, d.strapiQuizTeilen.EmailText)}
                                            target="_blank">
                                            <img className={s.iconShare} src={d.emailIcon.publicURL} alt="email share" />
                                        </a>
                                        <a href={this.shareTwitter(d.strapiQuizTeilen.URL, d.strapiQuizTeilen.Beschreibung)}
                                            target="_blank">
                                            <img className={s.iconShare} src={d.twitterIcon.publicURL} alt="twitter share" />
                                        </a>
                                    </div>
                            </PureModal>
                        </>
                    )
                }
                }
            />

        )
    }
}

