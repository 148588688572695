import React from 'react'
import s from './QuizQuestion.module.css'
import QuizAnimation from './QuizAnimation'
import TimeBar from '../Others/TimeBar';
import ButtonZurueck from '../ButtonZurueck';
import LogoSchauHin from '../LogoSchauHin'
import GelbElement from '../../images/GelbElement.svg'
import redCircle from '../../images/RedCircle.svg'
import greenCircle from '../../images/GreenCircle.svg'

export default class QuizQuestion extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            answerAdditionalClasses: [s.answerBlockHoverActivated,
            s.answerBlockHoverActivated,
            s.answerBlockHoverActivated,
            s.answerBlockHoverActivated],
            userClickedAnswer: false,
            lastAnswerWasCorrect: undefined
        };

        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.topBarRef = React.createRef();
        this.timeBarRef = React.createRef();
        this.handleGlobalClick = this.handleGlobalClick.bind(this);
        this.onBackClickQuestion = this.onBackClickQuestion.bind(this);

        this.clearQuestionInterval();
    }

    componentDidMount() {
        this.timeBarRef.current.startNew();
        this.clearQuestionInterval();
    }

    componentWillReceiveProps(nextProps) {
        this.timeBarRef.current.startNew();
        this.state = {
            answerAdditionalClasses: [s.answerBlockHoverActivated,
            s.answerBlockHoverActivated,
            s.answerBlockHoverActivated,
            s.answerBlockHoverActivated],
            userClickedAnswer: false,
            lastAnswerWasCorrect: undefined
        };

        this.setState(this.state);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleGlobalClick);
        this.clearQuestionInterval();
    }

    clearQuestionInterval() {
        if(this.questionChangeInterval!=null) {
            clearInterval(this.questionChangeInterval);
            this.questionChangeInterval = null;
        }
    }

    moveToNextQuestion() {
        this.clearQuestionInterval();
        document.removeEventListener('click', this.handleGlobalClick);
        this.props.nextQuestion(this.state.lastAnswerWasCorrect);

    }

    handleGlobalClick(event) {
        event.preventDefault();
        if (this.topBarRef && this.topBarRef.current != null &&
            !this.topBarRef.current.contains(event.target)) {
                this.moveToNextQuestion();
        }
    }


    getCorrectAnswerId() {
        let correctAnswerId = 0;
        for (let i = 0; i < this.props.currentQuestion.Answer.length; i++) {
            if (this.props.currentQuestion.Answer[i].Correct === true) {
                correctAnswerId = i;
                break;
            }
        }
        return correctAnswerId;
    }

    onChangeCheckBox = (event, answerId) => {
        event.preventDefault();

        if (this.state.userClickedAnswer) {
            return;
        }

        this.questionChangeInterval = setInterval(() => {
            this.moveToNextQuestion();
        }, 5000);

        this.timeBarRef.current.stop();
        document.addEventListener('click', this.handleGlobalClick);

        let lastAnswerWasCorrect = this.props.currentQuestion.Answer[answerId].Correct === true;

        this.state.answerAdditionalClasses = ['', '', '', ''];

        if (lastAnswerWasCorrect) {
            this.state.answerAdditionalClasses[answerId] = s.answerRightBlock + ' ' + s.circleActive;
        } else {
            this.state.answerAdditionalClasses[answerId] = s.answerFalseBlock + ' ' + s.circleActive;

            let correctAnswerId = this.getCorrectAnswerId();
            this.state.answerAdditionalClasses[correctAnswerId] = s.answerRightBlock;
        }

        this.state.lastAnswerWasCorrect = lastAnswerWasCorrect;
        this.state.userClickedAnswer = true;
        this.setState(this.state)
    }

    onBackClickQuestion() {
        this.timeBarRef.current.stop();
        this.props.onBackClick();
    }

    render() {
        return (
            <div className={s.wrapper}>
                <div style={{ backgroundColor: this.props.background }} className={s.fullscreen}>
                   <div className={s.limitation}>
                   <div  ref={this.topBarRef} >
                        <ButtonZurueck onClick={this.onBackClickQuestion} />
                        <LogoSchauHin />
                    </div>

                    <div className={s.container}>
                        <div className={s.animatioBlock}>
                            <QuizAnimation
                            initIndex={1}
                            showMiddle={true}
                                currentQuestionIndex={this.props.currentQuestionIndex + 1} />
                        </div>
                        <div className={s.questionBlock}>
                            <h2 className={s.question}>
                                {this.props.currentQuestion.QuestionText}
                            </h2>
                            <div className={s.answers}>

                                <div className={`${s.answerBlock} ${this.state.answerAdditionalClasses[0]}`}
                                    onClick={(e) => { this.onChangeCheckBox(e, 0) }} >
                                    <input type="checkbox" />
                                    <div className={s.circle} >
                                        <div >
                                            <img className={s.circleActiveGelb} src={GelbElement} alt="" />
                                        </div>
                                    </div>
                                    <div >
                                        <h2>
                                            <span className={s.answerText}>
                                               {this.props.currentQuestion.Answer[0].AnswerText}
                                                <span>
                                                    <img className={s.circleBlockRed} src={redCircle} alt="" />
                                                    <img className={s.circleBlockGreen} src={greenCircle} alt="" />
                                                </span>
                                            </span>
                                        </h2>
                                    </div>
                                </div>

                                <div className={`${s.answerBlock} ${this.state.answerAdditionalClasses[1]}`}
                                    onClick={(e) => { this.onChangeCheckBox(e, 1) }}>
                                    <input type="checkbox" />
                                    <div className={s.circle}>
                                        <div >
                                            <img className={s.circleActiveGelb} src={GelbElement} alt="" />
                                        </div>
                                    </div>
                                    <div>
                                        <h2>
                                            <span className={s.answerText}>
                                               {this.props.currentQuestion.Answer[1].AnswerText}
                                                <span >
                                                    <img className={s.circleBlockRed} src={redCircle} alt="" />
                                                    <img className={s.circleBlockGreen} src={greenCircle} alt="" />
                                                </span>
                                            </span>
                                        </h2>
                                    </div>
                                </div>


                                <div className={`${s.answerBlock} ${this.state.answerAdditionalClasses[2]}`}
                                    onClick={(e) => { this.onChangeCheckBox(e, 2) }}>
                                    <input type="checkbox" />
                                    <div className={s.circle}>
                                        <div >
                                            <img className={s.circleActiveGelb} src={GelbElement} alt="" />
                                        </div>
                                    </div>
                                    <div>
                                        <h2>
                                            <span className={s.answerText}>
                                               {this.props.currentQuestion.Answer[2].AnswerText}
                                                <span >
                                                    <img className={s.circleBlockRed} src={redCircle} alt="" />
                                                    <img className={s.circleBlockGreen} src={greenCircle} alt="" />
                                                </span>
                                            </span>
                                        </h2>
                                    </div>
                                </div>


                                <div className={`${s.answerBlock} ${this.state.answerAdditionalClasses[3]}`}
                                    onClick={(e) => { this.onChangeCheckBox(e, 3) }}>
                                    <input type="checkbox" />
                                    <div className={s.circle}>
                                        <div >
                                            <img className={s.circleActiveGelb} src={GelbElement} alt="" />
                                        </div>
                                    </div>
                                    <div >
                                        <h2>
                                            <span className={s.answerText}>
                                              {this.props.currentQuestion.Answer[3].AnswerText}
                                                <span >
                                                    <img className={s.circleBlockRed} src={redCircle} alt="" />
                                                    <img className={s.circleBlockGreen} src={greenCircle} alt="" />
                                                </span>
                                            </span>
                                        </h2>
                                    </div>
                                </div>
                                <TimeBar ref={this.timeBarRef} nextQuestion={this.props.nextQuestion} />
                            </div>
                        </div>
                        <div className={s.navigation} >
                            {this.props.questionProgress.map(e =>
                                <div className={`${s.navigationBlock} ${e ? s.navigationBlockSelected : ''}`}></div>
                            )}
                        </div>
                    </div>

                   </div>
                </div>
            </div>
        )
    }

}