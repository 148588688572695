import React, { Component } from 'react';
import s from './ButtonZurueck.module.css'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'


export default class ButtonZurueck extends Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
        query MyQueryButtonZurueck {
           buttonZurueck: file(relativePath: { eq: "ButtonZurueck.svg" }){
          publicURL
           } 
        }
        `}
                render={d => {
                    return (
                        <div onClick={this.props.onClick}>
                            <Link to={this.props.link} className={s.buttonZurueck}>
                                <img className={s.ButtonZurueckIcon} src={d.buttonZurueck.publicURL} alt="button" />
                                <p className={s.buttonZurueckText}>ZURÜCK</p>
                            </Link>
                        </div>
                    )
                }
                }
            />

        )
    }
}

