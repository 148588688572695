import React from 'react'
import s from './TimeBar.module.css'



export default class TimeBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeFormWidth : '0%'
        };
    
        this.startNew = this.startNew.bind(this);
        this.stop = this.stop.bind(this);

        this.timerStarted = false;
    }

    stop() {
        clearInterval(this.timeInterval)
        this.timerStarted = false;
        this.timeSpend = 0;
    }

    startNew() {
        this.timeSpend = 0;
        this.timeToGo = 30000
        if(!this.timerStarted) {
            this.timeInterval = setInterval(() => {
                this.timeSpend = this.timeSpend + 100;
                if(this.timeSpend>=this.timeToGo) {
                    this.stop();
                    this.props.nextQuestion(false);
                }
                this.setState({timeFormWidth: this.timeSpend/this.timeToGo*100+'%'});
            }, 100);
            this.timerStarted = true;
        }
    }

    render() {
        return(
            <div className={s.timeBar}>
            <div className={s.timeForm} style={{width:this.state.timeFormWidth}}></div>
            </div>
        )
    }

}