import React from 'react'
import s from './Test.module.css'
import circleX from '../images/GrayCircle.svg'
import MedienTeilen from '../images/MedienTeilen.svg'
import { StaticQuery, graphql } from 'gatsby'

export default class Dialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { show: false }
    this.modal = React.createRef();
  }

  open() {
    this.modal.current.showModal();
  }

  showModal = () => {
    this.setState({ show: true });
  }

  hideModal = () => {
    this.setState({ show: false });
  }

 
  render() {
    return (
      <main>
        <Modal ref={this.modal} show={this.state.show} handleClose={this.hideModal} className={s.modalMain} >
        </Modal>
      </main>
    )
  }
}

const Modal = ({ handleClose, show, data }) => {



return(
  <StaticQuery
  query={graphql`
    query MyQueryModalBlock {
      facebookIcon: file(relativePath: { eq: "facebook.svg" }){
          publicURL
      },
      twitterIcon: file(relativePath: { eq: "twitter.svg" }){
          publicURL
      },
          emailIcon: file(relativePath: { eq: "mail.svg" }){
          publicURL
      },
      strapiQuizTeilen{
          EmailBetreff,
          EmailText,
          URL,
          Beschreibung
      }    
  }`}
  render={d => {

    const showHideClassName = show ? `${s.modalDisplayBlock} ${s.modal}` : `${s.modalDisplayNone} ${s.modal}`;
    let shareFacebook =(url, quote)=> {
       url = encodeURI(url);
       quote = encodeURI(quote);
       let shareLink = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${quote}`;
       return shareLink;
     }
     
     let shareEmail =(emailBetreff, emailText)=> {
       emailText = encodeURI(emailText);
       emailBetreff = encodeURI(emailBetreff);
     
       return `mailto:%20?subject=${emailBetreff}&body=${emailText}`;
     
     }
     
      let shareTwitter =(url, text)=> {
       url = encodeURI(url);
       text = encodeURI(text);
       let shareLink = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
       return shareLink;
     };
     
     return (
       <div className={showHideClassName}>
         <section className={s.modalMain}>
           <div className={s.headerBlock}>
             <img className={s.medienTeilen}  src={MedienTeilen} alt=""/>
             <h1>Medienquiz teilen</h1>
             <button onClick={handleClose}>
               <img  className={s.closeButton} src={circleX} alt="" />
             </button>
           </div>
   
           <div className={s.modalBoby}>
             <div className={s.ShareIconBlock}>
               <a href={shareFacebook(d.strapiQuizTeilen.URL, d.strapiQuizTeilen.Beschreibung)}
                 target="_blank" rel="noreferrer" >
                 <img className={s.iconShare} src={d.facebookIcon.publicURL} alt="facebook share" />
               </a>
               <a href={shareEmail(d.strapiQuizTeilen.EmailBetreff, d.strapiQuizTeilen.EmailText)}
                 target="_blank" rel="noreferrer" >
                 <img className={s.iconShare} src={d.emailIcon.publicURL} alt="email share" />
               </a>
               <a href={shareTwitter(d.strapiQuizTeilen.URL, d.strapiQuizTeilen.Beschreibung)}
                 target="_blank" >
                 <img className={s.iconShare} src={d.twitterIcon.publicURL} alt="twitter share" />
               </a>
             </div>
           </div>
         </section>
       </div>
     );

    }
  }
/>
);

}

 
