import React from 'react'
import QuizWelcomeScreenBlock from '../QuizWelcomeScreen/QuizWelcomeScreenBlock'
import QuizQuiestion from '../QuizQuestion/QuizQuestion'
import FinishScreen from '../FinishScreen/FinishScreen'




export default class QuizMainComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        showWelcomeScreen: true,
        showQuestion: false,
        showFinalScreen: false,
        correctAnswers: [],
        currentQuestionIndex : 0,
        currentQuestion:  this.props.data.strapiQuizzes.Question[0]

    };
    this.startQuiz = this.startQuiz.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  nextQuestion(lastAnswerWasCorrect) {
    this.state.correctAnswers.push(lastAnswerWasCorrect);
    let currentQuestionIndex = this.state.currentQuestionIndex + 1;
    if(currentQuestionIndex < this.props.data.strapiQuizzes.Question.length) {
      let currentQuestion = this.props.data.strapiQuizzes.Question[currentQuestionIndex];
      let state = this.state;
      state.currentQuestionIndex = currentQuestionIndex;
      state.currentQuestion = currentQuestion;
      this.setState(state);

    } else {
      this.state.showQuestion = false;
      this.state.showFinalScreen = true;
      this.setState(this.state);
    }
  }

  getQuestionsProgress = () => {

      let result = this.props.data.strapiQuizzes.Question.map(e => false);
      result[this.state.currentQuestionIndex] = true;
      return result;
  }


  startQuiz = () => {
    let state = this.state;
    state.showWelcomeScreen = false;
    state.showQuestion = true;
    this.setState(state);
  }

  getCurrentComponent = () => {
    if(this.state.showWelcomeScreen) {
      return  <QuizWelcomeScreenBlock
      logoSchauHin={this.props.data.logoSchauHin.publicURL}
       data={this.props.data}
        startQuiz={this.startQuiz} />;
    } else if(this.state.showQuestion){
      return <QuizQuiestion 
             logoSchauHin={this.props.data.logoSchauHin.publicURL}
              currentQuestionIndex={this.state.currentQuestionIndex}
              background={this.props.data.strapiQuizzes.BackgroundRGB}
              currentQuestion={this.state.currentQuestion}
              questionProgress={this.getQuestionsProgress()} 
              nextQuestion={this.nextQuestion}
              onBackClick={this.resetState}
              />;
    } else if(this.state.showFinalScreen) {
    return  <FinishScreen
    logoSchauHin={this.props.data.logoSchauHin.publicURL}
    correctAnswers={this.state.correctAnswers}
    background={this.props.data.strapiQuizzes.BackgroundRGB}
    resetState={this.resetState}
    data={this.props.data.strapiFinishScreen}
    />;
    }
  }

  resetState=()=>{
    let newState = {
      showWelcomeScreen: true,
      showQuestion: false,
      showFinalScreen: false,
      correctAnswers: [],
      currentQuestionIndex : 0,
      currentQuestion:  this.props.data.strapiQuizzes.Question[0]
    }
    this.setState(newState);

  }

  render() {

    return (
      <div>
        {this.getCurrentComponent()}
      </div>
    )
  }

}